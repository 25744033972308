<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{category.name}}</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
			        <a-button icon="plus" shape="round" type="primary" @click="addProduct">
                        Add product
                    </a-button>
                </a-col>
			</a-row>
		</template>
        <a-checkbox-group @change="productSelected" style="display:inline">
        <a-table :columns="productcolumns" :data-source="productdata" :pagination="true" v-show="productdata.length">
            <template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
            <template slot="actionCol" slot-scope="row">
                <a-button shape="circle" icon="delete" @click="remove(row)"></a-button>
			</template>
        </a-table>
        </a-checkbox-group>
        <a-button shape="round" @click="confirmHandle" type="primary" style="margin-left:10px">Delete selected</a-button>
    </a-card>
</template>
<script>
import {getitem} from '@/api/category';
import {removeproductfromcategory} from '@/api/category';
import { Modal } from 'ant-design-vue';
import { message } from 'ant-design-vue';

export default({
    data() {
        return {
            productcolumns: [
                {
                    title:"", 
                    dataIndex:'productKey',
                    scopedSlots: { customRender: 'selectorColumn' },
                    width: 50,
                },
                {title:"Name", dataIndex:'name'},
                {title:"Unique Id", dataIndex:'uniqueid'},
                {
                    title:"Delete", 
                    dataIndex:'productKey',
                    key:'b',
                    scopedSlots: { customRender: 'actionCol' },
                    width: 100,
                }
            ],
            productdata:[],
            selectedProducts:[],
            category: {},
        
        }
    },
    methods:{
        productSelected(selectedValues){
           this.selectedProducts=selectedValues;
        },
         // function: category-19
        handleSelect(){
			this.selectedProducts.forEach(item => this.removefromcategory(item))
			},
        // function: category-19
        confirmHandle(){
            Modal.confirm({
        title: 'Are you sure to delete these products?',
        content: 'This operation cannot be recovered',
        okText: 'delete',
        cancelText: 'cancel',
        onOk:()=>{this.handleSelect()},
      });
        },
        // function: category-17
        addProduct(){
            this.$router.push({path:'/productsselector',query:{categoryid: this.categoryid}})
        },
        // function: category-18
        removefromcategory(key){
             removeproductfromcategory({categoryid:this.categoryid, productkey: key}).then(data=>{
                for (var i=0; i < this.productdata.length; i++)
						if (this.productdata[i].productKey==key)
							this.productdata.splice(i,1);
                message.success('The product has been removed from this category successfully!');
            });
        },
        // function: category-18
        remove(key){
        Modal.confirm({
        title: 'Are you sure to delete this product?',
        content: 'This operation cannot be recovered',
        okText: 'delete',
        cancelText: 'cancel',
        onOk:()=>{this.removefromcategory(key)},
      });
        }
    },
    mounted(){
        getitem(this.categoryid).then(data=>{
            this.productdata = data.products;
            this.category = data;
        })
    },
    props: {
        categoryid: String
    }
}
)
</script>